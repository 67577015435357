/* ------------------------------------------------------------ *\
    Reset
\* ------------------------------------------------------------ */

*,
*:before,
*:after {
  padding: 0;
  margin: 0;
  outline: 0;
  box-sizing: border-box;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
main {
  display: block;
}

html,
body {
  min-height: 100%;
}

html {
  tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-size-adjust: none;
}

img,
iframe,
video,
audio,
object {
  max-width: 100%;
}

img,
iframe {
  border: 0 none;
}

img {
  height: auto;
  display: inline-block;
  vertical-align: middle;
}

b,
strong {
  font-weight: bold;
}

address {
  font-style: normal;
}

svg:not(:root) {
  overflow: hidden;
}

a,
button,
input[type="submit"],
input[type="button"],
input[type="reset"],
input[type="file"],
input[type="image"],
label[for] {
  cursor: pointer;
}

a[href^="tel"],
button[disabled],
input[disabled],
textarea[disabled],
select[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="month"],
input[type="week"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="color"],
textarea,
a[href^="tel"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  display: none;
  -webkit-appearance: none;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

textarea {
  overflow: auto;
  resize: none;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

button,
select {
  text-transform: none;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

nav ul,
nav ol {
  list-style: none outside none;
}

/* ------------------------------------------------------------ *\
    General
\* ------------------------------------------------------------ */


@font-face {
  font-family: 'MarkOt';
  src: url('./fonts/MarkOT-Bold.otf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'MarkOt';
  src: url('./fonts/MarkOT-Italic.otf');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'MarkOt';
  src: url('./fonts/MarkOT-Medium.otf');
  font-weight: medium;
  font-style: normal;
}

@font-face {
  font-family: 'MarkOt';
  src: url('./fonts/MarkOT.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Blog Script';
  src: url('./fonts/Blog-script-custom.otf');
  font-weight: normal;
  font-style: normal;
}

html {
  font-size: 62.5%;
}

h1 {
  margin-bottom: 3rem
}

body,
html {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  min-height: 100vh;
  overflow-x: hidden;
}

body {
  font-size: 1.8rem;
}

#root {
  min-height: 100%;
}

.underline {
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 3rem !important;
}

.underline:after {
  content: '';
  position: absolute;
  width: 55px;
  height: 2px;
  background: black;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}

/* .smallUnderline {
  padding-bottom: 1.4rem;
  position: relative;
}

.smallUnderline:after {
  content: '';
  position: absolute;
  width: 50px;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: black;
} */

.fade-exit {
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: white;
  z-index: 999;
  transform: translateX(0);
}

.fade-exit-active {
  transform: translateX(-20px);
  opacity: 0;
  transition: opacity .3s, transform .3s;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-done {
  transition: opacity .3s;
  opacity: 1;
}

.fade-enter-done {
  transition: opacity .3s;
  opacity: 1;
}

.largeButton {
  padding: 35px 20px !important;
}

.fullWidth {
  width: 100%;
}

.loadContainer {
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ------------------------------------------------------------ *\
    Range input 
\* ------------------------------------------------------------ */

input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  margin: 0px 0;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  box-shadow: none;
  background: #73c3b9;
  border-radius: 0px;
  border: 0px;
}

input[type=range]::-webkit-slider-thumb {
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background: white;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: 0px;
  transform: translateY(-8px);
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #fffff0;
}

input[type="range"]::-moz-range-progress {
  width: 100%;
  height: 5px;
  cursor: pointer;
  box-shadow: none;
  background: #73c3b9;
  border-radius: 0px;
  border: 0px;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  box-shadow: none;
  background: white;
  border-radius: 0px;
  border: 0px;
}

input[type=range]::-moz-range-thumb {
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background: white;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: 0px;
  transform: translateY(0);
}

input[type=range]::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  box-shadow: none;
  background: white;
  border-radius: 0px;
  border: 0px;
}

input[type=range]::-ms-fill-lower {
  width: 100%;
  height: 5px;
  cursor: pointer;
  box-shadow: none;
  background: #73c3b9;
  border-radius: 0px;
  border: 0px;
}

input[type=range]::-ms-fill-upper {
  width: 100%;
  height: 5px;
  cursor: pointer;
  box-shadow: none;
  background: white;
  border-radius: 0px;
  border: 0px;
}

input[type=range]::-ms-thumb {
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background: white;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: 0px;
  transform: translateY(0);
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale(0);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes zoomOut {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(.5);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes show {
  from {
    opacity: 0;
    transform: translateX(50px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes showBackwards {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes showDownwards {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}